import { IGame } from "./interfaces";

export const games: IGame[] = [
  {
    title: "B.I.O.T.A.",
    keywords: ["2D", "metroidvania", "difficult"],
    tags: [
      {
        content: "out now",
      },
    ],
    links: [
      {
        icon: "gog",
        url: "http://gog.com/game/biota",
      },
      {
        icon: "steam",
        url:
          "https://store.steampowered.com/app/1640320/BIOTA/?utm_source=website&utm_campaign=gen_rev",
      },
      {
        icon: "twitter",
        url: "https://twitter.com/small_bros",
      },
      {
        icon: "instagram",
        url: "https://www.instagram.com/smallbros_games/",
      },
    ],
    selectedLink: null,
  },
  {
    title: "Impaler",
    tags: [{ content: "out now" }],
    keywords: ["Arena shooter with spikes"],
    links: [
      {
        icon: "gog",
        url: "https://www.gog.com/game/impaler",
      },
      {
        icon: "steam",
        url: "https://store.steampowered.com/app/1573090/Impaler/",
      },
      {
        icon: "twitter",
        url: "https://twitter.com/apptivus",
      },
    ],
    selectedLink: null,
  },
  {
    title: "Janosik",
    tags: [{ content: "out now" }, { content: "now free", inverted: true }],
    keywords: ["pixel art platformer", "precision", "jumping"],
    links: [
      {
        icon: "gog",
        url: "http://gog.com/game/janosik",
      },
      {
        icon: "steam",
        url:
          "https://store.steampowered.com/app/1412170/Janosik/?utm_source=website&utm_campaign=gen_rev ",
      },
      {
        icon: "twitter",
        url: "https://twitter.com/onixgames6",
      },
    ],
    selectedLink: null,
  },
  {
    title: "Janosik 2",
    keywords: ["3x bigger", "3xheroes", "3xfun"],
    links: [
      {
        icon: "gog",
        url: "http://gog.com/game/janosik_2",
      },
      {
        icon: "steam",
        url:
          "https://store.steampowered.com/app/1640400/Janosik_2?utm_source=website&utm_campaign=gen_rev ",
      },
      {
        icon: "twitter",
        url: "https://twitter.com/onixgames6",
      },
      {
        icon: "instagram",
        url: "https://www.instagram.com/onixgames6/",
      },
    ],
    selectedLink: null,
  },
  {
    title: "Keep Those Bugs Away From The Bomb",
    keywords: ["Fast-paced bullet hell roguelite"],
    links: [
      {
        icon: "steam",
        url:
          "https://store.steampowered.com/app/2133500/Keep_Those_Bugs_Away_From_the_Bomb/",
      },
      {
        icon: "twitter",
        url: "https://twitter.com/small_bros",
      },
      {
        icon: "instagram",
        url: "https://www.instagram.com/smallbros_games/",
      },
    ],
    selectedLink: null,
  },
  {
    title: "Project Warlock",
    keywords: ["retro carnage shooter"],
    tags: [{ content: "out now" }],
    links: [
      {
        icon: "gog",
        url: "https://www.gog.com/game/project_warlock",
      },
      {
        icon: "steam",
        url:
          "https://store.steampowered.com/app/893680/Project_Warlock/?utm_source=website&utm_campaign=gen_rev",
      },
      {
        icon: "twitter",
        url: "https://twitter.com/project_warlock",
      },
      {
        icon: "switch",
        url: "https://www.nintendo.com/games/detail/project-warlock-switch/",
      },
      {
        icon: "ps",
        url:
          "https://store.playstation.com/product/EP2627-CUSA19374_00-PROJECTWARLOCKEU",
      },
      {
        icon: "xbox",
        url:
          "https://www.microsoft.com/p/project-warlock/9p2x98hn98px?rtc=1&activetab=pivot:overviewtab",
      },
    ],
    selectedLink: null,
  },
  {
    title: "Project Warlock II",
    tags: [
      {
        content: "Early Access",
      },
    ],
    keywords: ["autosaves", "bigger maps", "8sided monsters"],
    links: [
      {
        icon: "gog",
        url: "https://www.gog.com/game/project_warlock_2",
      },
      {
        icon: "steam",
        url:
          "https://store.steampowered.com/app/1640300/Project_Warlock_II/?utm_source=website&utm_campaign=gen_rev",
      },
      {
        icon: "epic",
        url: "https://store.epicgames.com/en-US/p/project-warlock-ii-14efe5",
      },
      {
        icon: "twitter",
        url: "https://twitter.com/project_warlock",
      },
      {
        icon: "kickstarter",
        url:
          "https://www.kickstarter.com/projects/project-warlock-2/project-warlock-2",
      },
    ],
    selectedLink: null,
  },
  {
    title: "PROJECT WARLOCK:LOST CHAPTERS",
    keywords: [],
    links: [
      {
        icon: "steam",
        url:
          "https://store.steampowered.com/app/1043850/Project_Warlock_Lost_Chapters/",
      },
      {
        icon: "twitter",
        url: "https://twitter.com/project_warlock",
      },
    ],
    selectedLink: null,
  },
  {
    title: "Shardpunk:Verminfall",
    tags: [
      {
        content: "out now",
      },
    ],
    keywords: ["steampunk tactical rpg", "very difficult"],
    links: [
      {
        icon: "gog",
        url: "http://gog.com/game/shardpunk_verminfall",
      },
      {
        icon: "steam",
        url:
          "https://store.steampowered.com/app/1183800/Shardpunk_Verminfall/?utm_source=website&utm_campaign=gen_rev",
      },
      {
        icon: "twitter",
        url: "https://twitter.com/bryquthedev",
      },
      {
        icon: "unknown",
        url: "https://bryqu.itch.io/shardpunk",
      },
    ],
    selectedLink: null,
  },
  {
    title: "The Looter",
    keywords: ["postapocalyptic action platformer"],
    links: [
      {
        icon: "gog",
        url: "http://gog.com/game/the_looter",
      },
      {
        icon: "steam",
        url:
          "https://store.steampowered.com/app/1163020/The_Looter/?utm_source=website&utm_campaign=gen_rev",
      },
      {
        icon: "twitter",
        url: "https://twitter.com/tacticaldogs",
      },
    ],
    selectedLink: null,
  },
  {
    title: "Vergeworld",
    keywords: ["Post-apo high-speed combat racer"],
    links: [
      {
        icon: "steam",
        url:
          "https://store.steampowered.com/app/1975790/VergeWorld?utm_source=website&utm_campaign=gen_rev",
      },
      {
        icon: "twitter",
        url: "https://twitter.com/GamesBones",
      },
    ],
    selectedLink: null,
  },
  {
    title: "Undead West",
    keywords: ["western themed roguelite bullet hell"],
    links: [
      {
        icon: "steam",
        url: "https://store.steampowered.com/app/2527880/Undead_West/",
      },
      {
        icon: "twitter",
        url: "https://twitter.com/Deathless_Games",
      },
    ],
    selectedLink: null,
  },
];
