<template>
  <div class="game" :class="{ 'selected': selected }">
    <div class="left">
      <div class="title-box">
        <p class="title">
          {{ game.title }}
        </p>
        <div class="tags">
          <div class="button" v-for="tag in game.tags" :key="tag.content" :class="{ 'inverted': tag.inverted }">{{ tag.content }}</div>
        </div>
      </div>
      <div class="keywords">
          {{ keywordsJoined }}
      </div>
    </div>
    <div class="right">
      <a
        :href="link.url"
        target="_blank"
        :title="link.alt"
        v-for="link in orderedLinks(game.links)"
        :key="link.icon"
        class="icon"
        :class="{ 'selected': game.selectedLink === link, 'cursor': withCursor }"
        @mouseenter="selectLink(link)"
        @mouseleave="deselectLink()"
        >
        <img v-if="link.icon != 'kickstarter' && link.icon != 'epic'" :src="require(`@/assets/${link.icon}.svg`)" :alt="link.alt">
        <img v-else-if="link.icon == 'kickstarter'" src="@/assets/kickstarter.png" :alt="link.alt"/>
        <img v-else-if="link.icon == 'epic'" src="@/assets/epic.png" :alt="link.alt"/>
      </a>
    </div>
  </div>
</template>
<script lang="ts">
import { IGame, IGameLink } from '@/interfaces';
import { defineComponent } from 'vue'

const iconOrder = ['steam', 'gog', 'epic', 'ps', 'xbox', 'switch', 'unknown', 'twitter', 'instagram', 'kickstarter']

export default defineComponent({
  props: {
    game: {
      type: Object,
      default: () => ({}),
    },
    selected: {
      type: Boolean,
      default: false
    },
    withCursor: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    keywordsJoined(): string {
      return (this.$props.game as IGame).keywords.join(', ');
    }
  },
  methods: {
    selectLink(link: IGameLink) {
      this.$emit('selectedLink', link);
    },
    deselectLink() {
      this.$emit('selectedLink', null);
    },
    orderedLinks(links: IGameLink[]): IGameLink[] {
      return links.sort((a, b) => iconOrder.indexOf(a.icon) - iconOrder.indexOf(b.icon))
    }
  },
  watch: {
    selected(newVal) {
      if(!newVal) {
        this.$emit('selectedLink', null);
      }
    }
  }
})
</script>
