<template>
  <div id="app-content" :class="{'hide-cursor': selectedWithKeyboard }">
    <div class="container">
    <div class="logo-mobile">
      <div class="left">
        <div class="top"></div>
        <div class="middle"></div>
        <div class="bottom"></div>
      </div>
      <div class="center">
        <img src="@/assets/logo.svg"/>
      </div>
      <div class="right">
        <div class="top"></div>
        <div class="middle"></div>
        <div class="bottom"></div>
      </div>
    </div>
    <div class="top">
      <img id="desktop-top" src="@/assets/desktop_top.svg">
      <img id="tablet-top" src="@/assets/tablet_top.svg">
    </div>
    <!-- <newsletter class="hidden-tablet"/> -->
    <div class="games" ref="games">
      <game v-for="game in games" :with-cursor="selectedWithKeyboard" :key="game.title" :game="game" :selected="selectedGame === game" @mousemove="selectGame($event, game)" @selectedLink="selectedLinkHandler(game, $event)"></game>
    </div>
    <div class="joystick">
      <img id="joystick" :src="require(`@/assets/joystick_${this.theme}.svg`)"/>
      <div @click="toggleTheme" id="button"></div>
      <!-- <a href="https://mmphotography.ie/Janosik2Retrovibe/" target="_blank" id="easter-egg"></a> -->
    </div>
    <div class="bottom">
      <img id="desktop-bottom" src="@/assets/desktop_bottom.svg">
      <img id="tablet-bottom" src="@/assets/tablet_bottom.svg">
    </div>
    <!-- <newsletter class="hidden-mobile"/> -->
  </div>
  <footer class="footer">
    <div class="row">
      <a class="link" href="https://twitter.com/RetrovibeGames">
        <div>
          <img src="@/assets/twitter.svg"/>
          <span class="visible-tablet">retrovibegames</span>
        </div>
      </a>
      <a class="link" href="https://www.youtube.com/channel/UCls0OPgFVTk7aODl-7Rw-vg">
        <div>
          <img src="@/assets/youtube.svg"/>
          <span class="visible-tablet">retrovibegames</span>
        </div>
      </a>
      <a class="link hidden-tablet" href="https://www.twitch.tv/retrovibegames">
        <div>
          <img src="@/assets/twitch.svg"/>
          <span class="visible-tablet">retrovibegames</span>
        </div>
      </a>
      <a class="link" href="https://discord.gg/BzTtPRhBwu">
        <div>
          <img src="@/assets/discord.svg"/>
          <span class="hidden-tablet">/</span>retrovibegames
        </div>
      </a>
    </div>
    <div class="row">
      <a class="link visible-tablet" href="https://www.twitch.tv/retrovibegames">
        <div>
          <img src="@/assets/twitch.svg" class="visible-tablet"/>
          retrovibegames
        </div>
      </a>
      <a class="link" href="mailto:contact@retrovibegames.com">
        <div>
          <img src="@/assets/email.svg" class="visible-tablet"/>
          <div>contact@retrovibegames.<span id="email-com">com</span></div>
        </div>
      </a>
    </div>
  </footer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { games } from './games';
import Game from '@/components/Game.vue';
import Newsletter from '@/components/Newsletter.vue'
import { IGame, IGameLink } from './interfaces';

export default defineComponent({
  name: 'RetroVibe',
  data() {
    return {
      games,
      theme: '',
      selectedGameId: -1,
      selectedWithKeyboard: false
    }
  },
  components: {
    Game,
    Newsletter,
  },
  computed: {
    selectedGame(): IGame | null {
      return this.selectedGameId >= 0 ? this.games[this.selectedGameId] : null;
    }
  },
  beforeMount() {
    const p = Math.random();

    if(p >= 0.5) {
      this.theme = 'light'
    } else {
      this.theme = 'dark';
    }

    document.body.classList.toggle(this.theme);
  },
  mounted() {
    window.addEventListener('mousemove', this.mouseMove)
    window.addEventListener('keydown', this.keyboardHandler);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.keyboardHandler);
    window.removeEventListener('mousemove', this.mouseMove);
  },
  methods: {
    mouseMove() {
      this.selectedWithKeyboard = false;
    },
    toggleTheme() {
      const old = this.theme;

      this.theme = this.theme === 'light' ? 'dark' : 'light';
      document.body.classList.toggle(this.theme);
      document.body.classList.toggle(old);
    },
    selectGame(event: Event, game: IGame): void {
      if(!this.selectedGame || (this.selectedGame && this.selectedGame.title != game.title)) {
        this.selectedGameId = this.games.findIndex((g) => g.title === game.title);
      }
    },
    selectedLinkHandler(game: IGame, link: IGameLink) {
      game.selectedLink = link;
      this.selectedWithKeyboard = false;
    },
    scrollGameIntoViewById(gameId: number) {
      if(!this.selectedWithKeyboard) return;
      let games = document.querySelectorAll('.game');

      if(games.length < gameId) {
        console.error(`${gameId} is not found in games`)
        return
      }
      games[gameId].scrollIntoView(
        {
          behavior: 'smooth',
          block: 'center'
        }
      );
    },
    keyboardHandler(e: KeyboardEvent) {
      if(window.innerWidth < 1366) return;
      switch(e.key) {
        case 'ArrowUp':
          e.preventDefault();
          if(this.selectedGame) {
            this.selectedGame.selectedLink = null;
          }
          this.selectedGameId = this.selectedGameId < 0 ? this.games.length - 1 : Math.max(0, this.selectedGameId - 1);
          this.selectedWithKeyboard = true;
          this.scrollGameIntoViewById(this.selectedGameId)
          break;
        case 'ArrowDown':
          e.preventDefault();
          if(this.selectedGame) {
            this.selectedGame.selectedLink = null;
          }
          this.selectedGameId = this.selectedGameId < 0 ? 0 : Math.min(this.games.length - 1, this.selectedGameId + 1);
          this.selectedWithKeyboard = true;
          this.scrollGameIntoViewById(this.selectedGameId)
          break;
        case 'ArrowRight':
          e.preventDefault();
          if(this.selectedGame !== null) {
            let id = this.selectedGame.links.findIndex(l => l === this.selectedGame!.selectedLink);
            id++;
            if(id < this.selectedGame.links.length) {
              this.selectedGame.selectedLink = this.selectedGame.links[id];
            }
          }
          this.selectedWithKeyboard = true;
          break;
        case 'ArrowLeft':
          e.preventDefault();
          if(this.selectedGame !== null) {
            let id = Math.max(1, this.selectedGame.links.findIndex(l => l === this.selectedGame!.selectedLink));
            id--;
            this.selectedGame.selectedLink = this.selectedGame.links[id];
          }
          this.selectedWithKeyboard = true;
          break;
        case 'Enter':
          e.preventDefault();
          if(this.selectedGame !== null && this.selectedGame.selectedLink !== null) {
            window.open(this.selectedGame.selectedLink.url, '_newtab');
          }
          break; 
      }
    }
  }
});
</script>

<style lang="scss">
@import "@/assets/global.scss";

#email-com {
  display: inline-block;
  position: relative;
  right: 6px;
}

#easter-egg {
  display: block;
  position: absolute;
  width: 250px;
  height: 90px;
  background-color: transparent;
  top: -1%;
  left: 50%;
  transform: rotate(40deg);
  transform-origin: 0 0;
  cursor: url('~@/assets/cursor_pointer.svg');
}
</style>
