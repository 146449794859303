<template>
  <section class="newsletter">
    <div class="row align-center justify-center">
      <div class="row gap is-mobile align-center justify-center newsletter-cta">
        <img class="icon-48 attract" src="@/assets/email.svg"/>
        <p>Join our newsletter</p>
      </div>
      <form action="https://www.getrevue.co/profile/retrovibegames/add_subscriber" method="post" id="revue-form" name="revue-form" target="_blank">
      <input class="revue-form-field" placeholder="Email address..." type="email" name="member[email]" id="member_email">
      <input class="revue-form-field" placeholder="First name... (Optional)" type="text" name="member[first_name]" id="member_first_name" hidden>
      <input class="revue-form-field" placeholder="Last name... (Optional)" type="text" name="member[last_name]" id="member_last_name" hidden>
      <input type="submit" name="member[subscribe]" id="member_submit" value="Subscribe">
    </form>
    </div>
    
    <div class="legal">By subscribing, you agree with Revue’s <a target="_blank" href="https://www.getrevue.co/terms">Terms of Service</a> and <a target="_blank" href="https://www.getrevue.co/privacy">Privacy Policy</a></div>
  </section>
</template>