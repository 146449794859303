
import { IGame, IGameLink } from '@/interfaces';
import { defineComponent } from 'vue'

const iconOrder = ['steam', 'gog', 'epic', 'ps', 'xbox', 'switch', 'unknown', 'twitter', 'instagram', 'kickstarter']

export default defineComponent({
  props: {
    game: {
      type: Object,
      default: () => ({}),
    },
    selected: {
      type: Boolean,
      default: false
    },
    withCursor: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    keywordsJoined(): string {
      return (this.$props.game as IGame).keywords.join(', ');
    }
  },
  methods: {
    selectLink(link: IGameLink) {
      this.$emit('selectedLink', link);
    },
    deselectLink() {
      this.$emit('selectedLink', null);
    },
    orderedLinks(links: IGameLink[]): IGameLink[] {
      return links.sort((a, b) => iconOrder.indexOf(a.icon) - iconOrder.indexOf(b.icon))
    }
  },
  watch: {
    selected(newVal) {
      if(!newVal) {
        this.$emit('selectedLink', null);
      }
    }
  }
})
