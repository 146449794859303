
import { defineComponent } from 'vue';
import { games } from './games';
import Game from '@/components/Game.vue';
import Newsletter from '@/components/Newsletter.vue'
import { IGame, IGameLink } from './interfaces';

export default defineComponent({
  name: 'RetroVibe',
  data() {
    return {
      games,
      theme: '',
      selectedGameId: -1,
      selectedWithKeyboard: false
    }
  },
  components: {
    Game,
    Newsletter,
  },
  computed: {
    selectedGame(): IGame | null {
      return this.selectedGameId >= 0 ? this.games[this.selectedGameId] : null;
    }
  },
  beforeMount() {
    const p = Math.random();

    if(p >= 0.5) {
      this.theme = 'light'
    } else {
      this.theme = 'dark';
    }

    document.body.classList.toggle(this.theme);
  },
  mounted() {
    window.addEventListener('mousemove', this.mouseMove)
    window.addEventListener('keydown', this.keyboardHandler);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.keyboardHandler);
    window.removeEventListener('mousemove', this.mouseMove);
  },
  methods: {
    mouseMove() {
      this.selectedWithKeyboard = false;
    },
    toggleTheme() {
      const old = this.theme;

      this.theme = this.theme === 'light' ? 'dark' : 'light';
      document.body.classList.toggle(this.theme);
      document.body.classList.toggle(old);
    },
    selectGame(event: Event, game: IGame): void {
      if(!this.selectedGame || (this.selectedGame && this.selectedGame.title != game.title)) {
        this.selectedGameId = this.games.findIndex((g) => g.title === game.title);
      }
    },
    selectedLinkHandler(game: IGame, link: IGameLink) {
      game.selectedLink = link;
      this.selectedWithKeyboard = false;
    },
    scrollGameIntoViewById(gameId: number) {
      if(!this.selectedWithKeyboard) return;
      let games = document.querySelectorAll('.game');

      if(games.length < gameId) {
        console.error(`${gameId} is not found in games`)
        return
      }
      games[gameId].scrollIntoView(
        {
          behavior: 'smooth',
          block: 'center'
        }
      );
    },
    keyboardHandler(e: KeyboardEvent) {
      if(window.innerWidth < 1366) return;
      switch(e.key) {
        case 'ArrowUp':
          e.preventDefault();
          if(this.selectedGame) {
            this.selectedGame.selectedLink = null;
          }
          this.selectedGameId = this.selectedGameId < 0 ? this.games.length - 1 : Math.max(0, this.selectedGameId - 1);
          this.selectedWithKeyboard = true;
          this.scrollGameIntoViewById(this.selectedGameId)
          break;
        case 'ArrowDown':
          e.preventDefault();
          if(this.selectedGame) {
            this.selectedGame.selectedLink = null;
          }
          this.selectedGameId = this.selectedGameId < 0 ? 0 : Math.min(this.games.length - 1, this.selectedGameId + 1);
          this.selectedWithKeyboard = true;
          this.scrollGameIntoViewById(this.selectedGameId)
          break;
        case 'ArrowRight':
          e.preventDefault();
          if(this.selectedGame !== null) {
            let id = this.selectedGame.links.findIndex(l => l === this.selectedGame!.selectedLink);
            id++;
            if(id < this.selectedGame.links.length) {
              this.selectedGame.selectedLink = this.selectedGame.links[id];
            }
          }
          this.selectedWithKeyboard = true;
          break;
        case 'ArrowLeft':
          e.preventDefault();
          if(this.selectedGame !== null) {
            let id = Math.max(1, this.selectedGame.links.findIndex(l => l === this.selectedGame!.selectedLink));
            id--;
            this.selectedGame.selectedLink = this.selectedGame.links[id];
          }
          this.selectedWithKeyboard = true;
          break;
        case 'Enter':
          e.preventDefault();
          if(this.selectedGame !== null && this.selectedGame.selectedLink !== null) {
            window.open(this.selectedGame.selectedLink.url, '_newtab');
          }
          break; 
      }
    }
  }
});
